




















import { LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID } from '@/env';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { VENDOR_API_TYPE_META } from '@/features/domain-ui/vendor-api-type/constants';
import { VendorApiType } from '@/types/iot-portal';
import { StringProp } from '@/util/prop-decorators';
import { Component as ComponentType } from 'vue';
import { Component, Vue } from 'vue-property-decorator';
import { AppCustomerAddVendorApiWizardControlVendorApiFragment } from './__generated__/AppCustomerAddVendorApiWizardControlVendorApiFragment';
import AareonApi from './aareon/AareonApi.vue';
import AlertMailConfiguration from './alert-mail-configuration/AlertMailConfiguration.vue';
import AufzugheldenApi from './aufzughelden/AufzugheldenApi.vue';
import ComgyApi from './comgy/ComgyApi.vue';
import ConsumptionDataExchangeVarysMDL from './consumption-data-exchange-varys-mdl/ConsumptionDataExchangeVarysMDL.vue';
import ConsumptionDataExchangeVarysSW from './consumption-data-exchange-varys-sw/ConsumptionDataExchangeVarysSW.vue';
import EnerIqApi from './ener-iq/EnerIqApi.vue';
import ExternalSftpConfiguration from './external-sftp-configuration/ExternalSftpConfiguration.vue';
import IvmNotificationApi from './ivm/GenericIvmApi.vue';
import IvmApi from './ivm/IvmApi.vue';
import NukiApi from './nuki/NukiApi.vue';
import ViessmannApi from './viessmann/ViessmannApi.vue';
import ConsumptionDataExchangeAllpas from './consumption-data-exchange-allpas/ConsumptionDataExchangeAllpas.vue';
import ConsumptionDataExchangeAsko from './consumption-data-exchange-asko/ConsumptionDataExchangeAsko.vue';
import ConsumptionDataExchangeEADBuchholz from './consumption-data-exchange-ead-buchholz/ConsumptionDataExchangeEADBuchholz.vue';
import ConsumptionDataExchangeEADZeulenroda from './consumption-data-exchange-ead-zeulenroda/ConsumptionDataExchangeEADZeulenroda.vue';
import ConsumptionDataExchangeIbaenergie from './consumption-data-exchange-ibaenergie/ConsumptionDataExchangeIbaenergie.vue';
import ConsumptionDataExchangeMevivoEco from './consumption-data-exchange-mevivo-eco/ConsumptionDataExchangeMevivoEco.vue';
import IvmConsumptionApi from './ivm/IvmConsumptionApi.vue';
import GK40Heizungsprofi from './gk40-heizungsprofi/GK40Heizungsprofi.vue';

interface TypeOption {
  component: ComponentType;
  label: string;
  description?: string;
}

@Component({
  data() {
    return { selectedType: null };
  },
})
export default class AddVendorApiWizardControl extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private selectedType!: VendorApiType | null;

  private get typeOptions(): TypeOption[] {
    const showGenericNotifications = (): boolean => {
      return !LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID || LIMIT_GENERIC_NOTIFICATIONS_CUSTOMER_ID === this.customerId;
    };

    const components = {
      AareonApi,
      AufzugheldenApi,
      ComgyApi,
      EnerIqApi,
      NukiApi,
      ViessmannApi,
      IvmApi,
      ...(showGenericNotifications() ? { IvmNotificationApi } : {}),
      IvmConsumptionApi,
      AlertMailConfiguration,
      ExternalSftpConfiguration,
      ConsumptionDataExchangeVarysSW,
      ConsumptionDataExchangeVarysMDL,
      ConsumptionDataExchangeAllpas,
      ConsumptionDataExchangeAsko,
      ConsumptionDataExchangeEADBuchholz,
      ConsumptionDataExchangeEADZeulenroda,
      ConsumptionDataExchangeIbaenergie,
      ConsumptionDataExchangeMevivoEco,
      GK40Heizungsprofi,
    };

    return Object.entries(components)
      .map(([type, component]) => ({ component, type, label: type }))
      .map((option) => ({ ...option, ...VENDOR_API_TYPE_META[option.type as VendorApiType] }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private async onVendorApiAdded(
    api: AppCustomerAddVendorApiWizardControlVendorApiFragment,
    hide: () => void,
  ): Promise<void> {
    hide();

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: `Erfolgreich verbunden.` }],
    });

    this.$emit('vendor-api-added', api);
  }
}
