































import { StringProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Vue } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppCustomerGK40HeizungsprofiConfigurationViewQuery,
  AppCustomerGK40HeizungsprofiConfigurationViewQueryVariables,
} from './__generated__/AppCustomerGK40HeizungsprofiConfigurationViewQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: GK40HeizungsprofiConfigurationView): AppCustomerGK40HeizungsprofiConfigurationViewQueryVariables {
        return { customerId: this.customerId, vendorApiId: this.vendorApiId };
      },
      result(
        this: GK40HeizungsprofiConfigurationView,
        { data, stale }: ApolloQueryResult<AppCustomerGK40HeizungsprofiConfigurationViewQuery>,
      ): void {
        if (data && data.customers) {
          if (data.customers.first.gk40HeizungsprofiConfigurations.first === undefined && !stale) {
            this.$router.back();
          }
        }
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class GK40HeizungsprofiConfigurationView extends Vue {
  @StringProp(true)
  private readonly customerId!: string;

  @StringProp(true)
  private readonly vendorApiId!: string;

  private readonly customers?: AppCustomerGK40HeizungsprofiConfigurationViewQuery['customers'];
}
